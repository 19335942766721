import api from '../../services/backendApi';


const config = { headers: { 'Content-Type': 'application/json' } };

export default {
  update(data) {
    const { contractId } = data;
    return api.patch(`contracts/${contractId}/stay-info`, JSON.stringify(data), config);
  }
};
