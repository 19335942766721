export const MODULE_NAME = 'stayinguser';

export const ACTIONS = {
	UPDATE: `${MODULE_NAME}/UPDATE`,
};


export const MUTATIONS = {
	SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
};
