<template>
	<div class="payment-page">
		<v-app-bar
					app
					color="transparent"
					dark
					flat
					class="company--logo"
		>
			<div class="d-flex align-center">
				<v-img
						v-if="company"
						alt="Upgrade Estate Logo"
						class="my-4 mx-5"
						contain
						:src="logo"
						transition="scale-transition"
						width="176px"
						height="82px"
				/>

			</div>
			<v-spacer></v-spacer>
			<!-- <h3 class="white--text text-center mr-12">{{$t("general.betaal-pagina")}}</h3> -->
		</v-app-bar>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'Root',
		computed: {
			company() {
				return this.$route.params.company || null;
			},
			logo() {
				return require(`@/assets/${this.company.toLocaleLowerCase()}.svg`)
			}
		}
	};
</script>


<style lang="scss">
.payment-page {
	display: flex;
	height: 100%;	
}

.v-toolbar__content {
	height: auto !important;
	padding: 0 !important;
}

#router-view {
	display: flex;
	background-color: #F0F0F0;

	.page-wrapper {
		display: flex;
		flex-flow: column;
		width: 100%;
		overflow: auto;

		.page-header {
			display: inline-flex;
			flex: 0 0 auto;
		}

		.container {
			flex: 1 1 100%;
			overflow-y: auto;
		}
	}
}

.company {
	&--logo {
		border: 4px solid black;
		position: relative;
		z-index: 1000 !important;
	}
}

@media screen and (max-width: 37.5rem) {
	.company--logo {
		display: none !important;
	}
}
</style>
