<template>
	<v-layout row wrap class="pa-0 mt-5 justify-center company-page">
		<v-flex xs12 md9 justify-center class="ma-0 mt-5">

			<!-- IF TENANT HAS TO MAKE A CHOICE -->
			<v-card v-if="!isAnswered" flat class="card">
				<v-layout row wrap class="pa-0 justify-center" v-if="!updated">
					<!-- <p class="subtitle pl-10 pt-5 pb-0" v-html="$t( company + '.staying.intro')"></p> -->
					<p class="subtitle pl-10 pt-5 pb-0" v-html="$t('general.stayinguser.intro')"></p>
					<v-flex xs12 justify-center>
						<staying-user-list v-if="showListItems" :company="company" @updated="toggle"></staying-user-list>
					</v-flex>
				</v-layout>
				<v-layout row wrap class="pa-0" justify-center v-if="updated">
					<p class="subtitle pl-10 pt-5 pb-0" v-html="$t( company + '.staying.done')"></p>
					<v-flex xs12 justify-center align-center>
						<div style="margin: 0 15%" class="flex justify-center">
							<v-img
									:src="img"
									contain
									transition="scale-transition"
									max-width="600"
									text-center
									justify-center
									flex
									style="margin: 0 auto"
							/>
						</div>
					</v-flex>
				</v-layout>
			</v-card>

			<!-- IF CHOICE IS ALREADY MADE -->
			<v-card v-else flat class="card">
				<v-layout row wrap class="pa-0 justify-center" v-if="!updated">
					<!-- <p class="subtitle pl-10 pt-5 pb-0" v-html="$t( company + '.staying.intro')"></p> -->
					<p class="subtitle pl-10 pt-5 pb-0" v-html="$t('general.stayinguser.intro')"></p>

					<p class="px-10 pt-5 pb-0 mb-14" v-html="$t('general.stayinguser.already-payed')"></p>
				</v-layout>

			</v-card>
		</v-flex>
	</v-layout>
</template>

<script>
	import StayingUserList from './stayinguser-list';
	import api from '../../../services/backendApi';
  import {  pathOr } from 'ramda';


	export default {
		name: 'StayingUserPage',
		components: { StayingUserList },
		data: () => ({
			loading: true,
			basicUrl: process.env.VUE_APP_API_URL,
			company: 'upkot',
			updated: false,
			isAnswered: false,
			tenantsAnswer: null,
			showListItems: false,
		}),
		mounted() {
			const config = { headers: { 'Content-Type': 'application/json' } };

			api
				.get(`${this.basicUrl}/contracts/${this.$route.query.contractId}/stay-info`, config)
				.then(({data})=>{
          if(data.data.tenantLocale){
            this.$i18n.locale = pathOr({}, ['data', 'tenantLocale'], data);
          }

          this.tenantsAnswer = data.data.stayChoice;
        })


			this.company = this.$route.params.company.toLowerCase();
		},
		computed: {
			img() {
				return require(`@assets/kotkip_succes.png`);
			}
		},
		watch: {
			tenantsAnswer() {
				if( this.tenantsAnswer ==='UNDECIDED' || this.tenantsAnswer ==='') {
          this.isAnswered = false;
          this.showListItems = true
        }else{
          this.isAnswered = true;
        }
			}
		},
		methods: {
			toggle(){
				this.updated = !this.updated;
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import "@styles/colors.scss";

	.subtitle {
		width: 100%;
		text-align: left;
		font-size: 1.5em;
	}
	.company-page, .filter-block {
		width: 100vw;
		margin: 0;
	}

	.card {
		border-radius: 15px !important;
	}
</style>

