<template>
    <v-layout row wrap class="pb-5 justify-center">
        <v-flex xs12 md12 class="px-10">
            <v-progress-circular v-if="isSearching"
                                 :size="50"
                                 color="primary"
                                 indeterminate
            ></v-progress-circular>
            <div>
                <staying-list-item
                        :name="'staying'"
                        :id="0"
                        :selected="selected"
                        @toggle="toggleSelected"
                        @updateSelection="updateStayLeaveSelection"
                />
                <staying-list-item
                        :name="'leaving'"
                        :id="1"
                        :selected="selected"
                        :options="stayChoiceInfoItems_leaving"
                        @toggle="toggleSelected"
                        @updateSelection="updateStayLeaveSelection"
                />
                  <staying-list-item
                        :name="'undecided'"
                        :label="$t('general.stayinguser.undecided')"
                        :id="2"
                        :selected="selected"
                        :options="stayChoiceInfoItems_undecided"
                        @toggle="toggleSelected"
                        @updateSelection="updateStayLeaveSelection"
                />

                <p class="ma-3 mb-12 font-italic text-body-2" v-if="selected != 0" >{{ $t('general.stayinguser.undecided-note') }}</p>
                <!-- <v-textarea v-if="selected === 2" class="ma-3 my-5" placeholder="Vertel ons waarom je nog niks besloten hebt" v-model="undcidedRemarks"></v-textarea> -->
            </div>
        </v-flex>
        <v-layout row wrap class="pb-5 justify-center">
            <v-flex xs12 md4 justify-center class="pa-4">
                <v-btn outlined rounded class="text-uppercase white--text" x-large :color="company" block :disabled="!disableSave"
                       @click="updateStatus">{{ btn_save }}
                </v-btn>
            </v-flex>
        </v-layout>

    </v-layout>
</template>

<script>
    import {mapActions, mapState} from 'vuex';
    import {ACTIONS} from '../stayinguser.constants';
    import { ACTIONS as TOAST_ACTIONS } from '../../../components/toast/toast.constants.js';
    import StayingListItem from './stayinguser-list-item';
    import StayingList from "./stayinguser-list";

    export default {
        name: 'StayingList',
        components: {
            StayingList,
            StayingListItem
        },
        props: ['company'],
        data() {
            return {
                disableSave: false,
                required_select: false,
                btn_save: this.$t('general.save'),
                stayChoiceInfoItems_leaving: [],
                stayChoiceInfoItems_undecided: [],
                selected: -1,
                remarks: "",
                otherMoreInfo:""
            }
        },
        computed: {
            ...mapState({
                isSearching: state => state.payment.isLoading
            }),
        },
        mounted() {
            const filter = {
                ...this.$route.query
            };

            let i;
            for (i = 1; i < 10; i++) {
              // 8 = the id in localize of 'OTHER', in leaving , it's nice that this is at the bottom
              if(i!=8){
                this.stayChoiceInfoItems_leaving.push({
                  name: this.$t(`general.stayinguser.leaving.answer${i}`),
                  _id: `general.stayinguser.leaving.answer${i}`
                })
              }

            };
            // add OTHER to leaving array
             this.stayChoiceInfoItems_leaving.push({
                  name: this.$t(`general.stayinguser.leaving.answer8`),
                  _id: `general.stayinguser.leaving.answer8`
                });

            for (i = 1; i < 6; i++) {
              // 5 = the id in localize of 'OTHER', in undecided , it's nice that this is at the bottom
              if(i!=5){
                this.stayChoiceInfoItems_undecided.push({
                  name:this.$t(`general.stayinguser.undecided.answer${i}`),
                  _id: `general.stayinguser.undecided.answer${i}`
                  });
              }
            };
            // add OTHER to leaving array
             this.stayChoiceInfoItems_undecided.push({
              name: this.$t(`general.stayinguser.undecided.answer5`),
              _id: `general.stayinguser.undecided.answer5`
             });
        },
        methods: {
            ...mapActions({
                update: ACTIONS.UPDATE,
                makeToast: TOAST_ACTIONS.MAKE_TOAST,
            }),
            updateStayLeaveSelection(item){
                this.disableSave = item.isValid;

                this.remarks = item.selectedOption || "";
                this.required_select = item.isValid;
                this.otherMoreInfo = item.otherMoreInfo;
            },

            updateStatus() {
                if (this.required_select === true && this.selected >-1) {
                    this.update({
                        remarks: this.remarks,
                        choice: this.selected,
                        stayChoiceOtherInfo : this.otherMoreInfo,
                        ...this.$route.query
                    });
                    this.$emit('updated');
                } else {
                    this.makeToast({ message: this.$t('general.stayinguser.required-fields'), type: 'error' });
                }
            },
            toggleSelected(paymentId) {
                this.remarks = '';
                this.required_select = false;
                this.selected = paymentId;
            }
        }
    };
</script>


<style lang="scss" scoped>
    .line {
        width: 30px;
        height: 3px;
        color: #333; /* old IE */
        background-color: #333; /* Modern Browsers */
    }
</style>
