import { ACTIONS, MUTATIONS } from './stayinguser.constants';
import stayingService from './stayinguser.service';

const payment = {
	state: {
		loading: false,
	},
	actions: {
		[ACTIONS.UPDATE]({ state, commit }, data) {
			console.log(data);
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				stayingService.update(data)
					.then((result) => {
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
	},
	mutations: {
		[MUTATIONS.SET_IS_LOADING](state, loadingState) {
			state.loading = loadingState;
		},
	},
};


export default payment;
