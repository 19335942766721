<template>
	<v-card
			class="ma-3 my-5 pointer payment-row"
			flat
			v-if="name"
			:class="{'selected-row': isSelected}"
	>
		<v-list-item three-line @click="$emit('toggle', id)">
			<div class="check-mark-container" v-if="isSelected">
				<v-img
						alt="checkMark"
						class="check-mark"
						src="@assets/icons/check-mark.svg"
						transition="scale-transition"
						width="40px"
				/>
			</div>

			<v-list-item-avatar class="mr-5" tile size="80">
				<v-img
						class="item-logo pa-5"
						:src="img"
						contain
						transition="scale-transition"
						width="25px"
				/>
			</v-list-item-avatar>

			<v-list-item-content>
				<v-list-item-title class="headline mb-1">{{ getName }}</v-list-item-title>
        <v-list-item-subtitle v-if="selected === id && options">
          <v-select
                    class="ma-3 my-5"
                    item-text="name"
                    item-value="_id"
                    :items="options"
                    :label="$t('general.stayinguser.dropdown-decission')"
                    outlined
                    hide-details
                    v-model="selectedOption"
                    @change="updateSelection($event)"
                />
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="selected === id && options && (selectedOption=='general.stayinguser.leaving.answer8' || selectedOption =='general.stayinguser.undecided.answer5' || selectedOption =='general.stayinguser.leaving.answer5') ">
            <v-textarea v-on:keyup="emitValues()" class="ma-3 text-body-2" outlined :label="$t('general.stayinguser.other-choice-more-info')" v-model="otherMoreInfo" required></v-textarea>
        </v-list-item-subtitle>
			</v-list-item-content>

		</v-list-item>

	</v-card>
</template>

<script>
	export default {
		props: {
			name: {
				type: String,
			},
			id: {
				type: Number
			},
			selected: {
				type: Number
			},
      options:{
        type: Array
      }
		},
    data() {
			return {
        selectedOption:null,
        otherMoreInfo:null
			}
		},
		computed: {
			getName() {
				return this.$t(`general.stayinguser.${this.name}.0`);
			},
			img() {
				return require(`@assets/icons/${this.name}.svg`);
			},
			isSelected() {
				return this.selected === this.id;
			},
		},
    methods: {
      updateSelection(selected){
        this.selectedOption = selected;
        this.otherMoreInfo = null;
        this.emitValues();
      },
      emitValues(){
        let isValid = false;
        isValid = this.options ? this.selectedOption != null : true;
        isValid = (this.selectedOption=='general.stayinguser.leaving.answer8' || this.selectedOption=='general.stayinguser.leaving.answer5' || this.selectedOption =='general.stayinguser.undecided.answer5' ) ? !!(this.otherMoreInfo && this.otherMoreInfo.length>10) : isValid;
        this.$emit('updateSelection',{selectedOption:this.selectedOption,otherMoreInfo:this.otherMoreInfo,isValid});
      }
    },
    watch: {
      selected (val) {
        if(val === this.id){
          this.emitValues();
        }
      },
    },
	};
</script>

<style lang="scss" scoped>
	@import "@styles/colors.scss";

	.payment-row {
		background-color: $grey;
		border-radius: 10px !important;
		border: solid 2px;
		border-color: $grey !important;

		&.selected-row {
			background-color: white;
			border-color: $upliving !important;
		}

		&.disabled-row {
			border-color: #c9c9c9 !important;
		}
	}


	.payment-price {
		position: absolute;
		right: 20px;
		width: 200px;
		color: #C7C7C7;
		text-align: center;

		p {
			font-size: 32px;
			line-height: 0;
		}

		.price {
			padding-top: 15px;
			line-height: 40px;
			font-size: 50px;
		}
	}

	.payment-price-payed {
		position: absolute;
		right: 20px;
		width: 200px;
		color: #C7C7C7;
		text-align: center;

		p {
			padding-top: 17px;
			font-size: 32px;
			font-weight: bolder;
			line-height: 0;
		}
	}

	.check-mark-container {
		position: absolute;
		top: -20px;
		right: 20px;
	}
</style>
